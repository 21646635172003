import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { useAuthStore } from '@/stores/auth-store';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: () => import(/* webpackChunkName: "login" */ '../views/InstructionsView.vue')
  }, {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from, next) {
      const auth = useAuthStore();
      auth.logout();
      next({ name: 'login' });
    }
  },
  {
    path: '/registration/:email/:activation_key',
    name: 'activation',
    component: () => import(/* webpackChunkName: "login" */ '../views/RegistrationView.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import(/* webpackChunkName: "login" */ '../views/RegistrationView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "login" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/password',
    name: 'changepasswordlogin',
    component: () => import(/* webpackChunkName: "login" */ '../views/ChangePassword.vue')
  },
  {
    path: '/password/:email/:activation_key',
    name: 'changepassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/ChangePassword.vue')
  },
  {
    path: '/need-filling',
    name: 'need-filling',
    component: () => import(/* webpackChunkName: "need-filling" */ '../views/NeedFillingView.vue')
  },
  {
    path: '/need-modify/:id',
    name: 'need-modify',
    component: () => import(/* webpackChunkName: "need-filling" */ '../views/NeedModifyView.vue')
  },
  {
    //TODO mikä tarve? Tulee hakea ko. tarve url:iin. Tulee muuttaa omaksi url:ksi tämä
    path: '/need-preview/:id',
    name: 'need-preview',
    component: () => import(/* webpackChunkName: "need-preview" */ '../views/NeedPreviewView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/drafts',
    name: 'drafts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DraftsView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue')
  },
  {
    path: '/admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminMainView.vue'),
    children: [
      {
        path: '',
        name: 'admin',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminView.vue'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserView.vue'),
      }, {
        path: 'user-settings',
        name: 'user-settings',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserSettingsView.vue'),
      }, {
        path: 'need-listing/:userid?',
        name: 'need-listing',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/NeedListingView.vue'),
      }, {
        path: 'comment-listing/:userid?',
        name: 'comment-listing',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/CommentListingView.vue'),
      }, {
        path: 'email-templates-listing/:id?',
        name: 'email-templates-listing',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/EmailTemplatesView.vue'),
      }, {
        path: 'agreements-listing/:id?',
        name: 'agreements-listing',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/AgreementListingView.vue'),
      }, {
        path: 'admin-need-modify/:id',
        name: 'admin-need-modify',
        component: () => import(/* webpackChunkName: "need-filling" */ '../views/admin/NeedModifyView.vue')
      },]
  },
  {
    path: '/read-agreement/:id',
    name: 'read-agreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ReadAgreement.vue')
  },
  { path: '/404', name: '404', component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFoundView.vue') },
  { path: '*', name: 'page-not-found', component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFoundView.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['login', 'logout', 'registration', 'activation', '404', 'forgot-password', 'changepassword', 'read-agreement', 'instructions'];
  const authRequired = !publicPages.includes(to.name ? to.name : '404');
  const auth = useAuthStore();
  const valid = auth.isLoggedIn() ? true : false;
  //console.log('auth required', authRequired);
  //console.log('authenticated', auth.user, valid);
  //console.log('from', from, to);
  if (authRequired && !valid) {
    if (!auth.returnUrl) auth.returnUrl = to.fullPath;
    //console.log('redirecting to login ...');
    next({ name: 'login', replace: true });
    return;
    //next({ name: 'about' });
  }
  next({ replace: true });
});
router.afterEach(async (to, from) => {
  //TODO analytiikkaa navigoinnista? 
  //agreements after login
  const auth = useAuthStore();
  console.log(from.name, to.name);
  const valid = auth.isLoggedIn() ? true : false;
  //if (from.name === 'login' && to.name !== 'login') {
  if (valid) auth.checkAgreements();
  //}
});
export default router;
