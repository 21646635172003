//import { ValidationObserver, ValidationProvider, extend, localize, configure } from 'vee-validate';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, max, alpha, min } from 'vee-validate/dist/rules';
import Vue from 'vue';
import i18n from '@/i18n';
import { isUrl, isDomain } from '@/helpers/utils';
//import i18n from '@/i18n';
/** RULES */
extend('email', email);
//extend('required', required);
extend('max', max);
extend('alpha', alpha);
extend('min', min);
extend('sameAs', {
    validate(value, { length }) {
        return value === length;
    },
    params: ['length', 'field2'],
    message: (field, { field2 }) => i18n.t('validation.VALIDATION_ERROR_SAMEAS', { field: field, field2: field2 }) as string,
});
extend('listLength', {
    validate(value, { length }) {
        return value.length >= length;
    },
    params: ['length'],
    message: (field) => i18n.t('validation.VALIDATION_ERROR_ARRAYMINLENGTH', { field: field }) as string,
});
extend('url', {
    validate(value) {
        return isUrl(value);
    },
    message: i18n.t('validation.VALIDATION_ERROR_URL').toString()
});
extend('phone', {
    validate(value) {
        if (value) {
            const regex = /^[+|\d][\s/0-9]*$/;
            return value.match(regex);
        }
        return true;
    },
    message: i18n.t('validation.VALIDATION_ERROR_PHONE').toString()
})
extend('required', {
    ...required,
    message: (field) => i18n.t('validation.VALIDATION_ERROR_REQUIRED', { field: field }) as string,
});
extend('domainOrWildCard', {
    validate(value) {
        return isDomain(value) || value === '*';
    },
    message: i18n.t('validation.VALIDATION_ERROR_URL').toString()
});
extend('domain', {
    validate(value) {
        return isDomain(value);
    },
    message: i18n.t('validation.VALIDATION_ERROR_URL').toString()
});
/** date */
/*extend('beforeDate', {
    validate(value, { length1 }) {
        return value.length >= length1
    },
    params: ['length'],
});*/

/** LOCALIZATION */
//import en from 'vee-validate/dist/locale/en.json';
//import fi from 'vee-validate/dist/locale/fi.json';
//import en from '../locales/validation/en.json';
//import fi from '../locales/validation/fi.json';
/*
localize(i18n.locale, i18n.locale === 'fi' ? fi : en);
localize('en', en)
*/
/*configure(
    {
        i18n,
        dictionary: {
            en: en,
            fi: fi
        }
    });*/
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);